import React, { useState } from 'react';
import axios from 'axios';
import '../css/login.css'
import config from '../config';

const Login = ({ setToken }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.API_URL}/auth/login`, { username, password }, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            setToken(response.data.accessToken);
        } catch (error) {
            setError('Invalid credentials');
        }
    };

    return (
        <div className='loginPage'>
            <div className='loginContent'>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                        />
                    </div>
                    {error && <p>{error}</p>}
                    <button className="login" type="submit">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
