import React from 'react';

const ConfirmationModal = ({ title, message, isOpen, onConfirm, onCancel, children }) => {
    if (!isOpen) return null;

    return (
        <div className="confirmation-modal">
            <div className='confirmation-modal-content'>
                <h2>{title}</h2>
                <p>{message}</p>
                {children}
                <button className='btn' onClick={onConfirm}>Yes</button>
                <button className='btn' onClick={onCancel}>No</button>
            </div>
        </div>
    );
};

export default ConfirmationModal;
