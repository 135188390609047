import config from './config';

const WEBSOCKET_URL = config.WEBSOCKET_URL;
let ws;
let resolveWebSocketPromise;
let webSocketPromise;

function createWebSocketPromise() {
    webSocketPromise = new Promise((resolve) => {
        resolveWebSocketPromise = resolve;
    });
}

createWebSocketPromise();

function connectWebSocket(token, onMessageCallback) {
    ws = new WebSocket(WEBSOCKET_URL);

    ws.onopen = () => {
        console.log('WebSocket connected');
        resolveWebSocketPromise();
        if (token) {
            sendMessage({ type: 'auth', token });
        }
    };

    ws.onclose = (event) => {
        console.log('WebSocket disconnected', event);
        ws = null;
        createWebSocketPromise();
        setTimeout(() => connectWebSocket(token, onMessageCallback), 5000);
    };

    ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws = null;
        createWebSocketPromise();
        setTimeout(() => connectWebSocket(token, onMessageCallback), 5000);
    };

    ws.onmessage = (event) => {
        if (onMessageCallback) {
            try {
                onMessageCallback(event);
            } catch (error) {
                console.error('Error handling WebSocket message:', error);
            }
        }
    };

    return ws;
}

function getWebSocket() {
    return ws;
}

function sendMessage(message) {
    webSocketPromise.then(() => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(message));
        } else {
            console.error('WebSocket is not open');
        }
    }).catch(error => {
        console.error('WebSocket Promise error:', error);
    });
}

export { connectWebSocket, getWebSocket, sendMessage };
