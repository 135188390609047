import React, { useState } from 'react';
import ModalContent from './Modal/ModalContents';
import Modal from './Modal/Modal';
import '../css/modal.css'

const Header = () =>{
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
      };
    
    const handleCloseModal = () => {
    setIsModalOpen(false);
    };

    return (
        <div className='headerBlock'>
            <h3>WhatsApp Bot Manager</h3>
            <div className='modalBlock'>
            <button className='btn' onClick={handleOpenModal}>API Endpoints</button>
                {isModalOpen && (
                    <Modal onClose={handleCloseModal}>
                        <ModalContent /> 
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default Header;