export const formatBotInfo = (bot) => {
    // Форматирование даты
    const formattedDate = new Date(bot.registration_date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
    });

    return {
        ...bot,
        registration_date: formattedDate
    };
};