import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CreateBot from './components/CreateBot';
import Header from './components/Header';
import Login from './components/Login';
import './css/App.css';
import './css/media.css';
import { jwtDecode } from 'jwt-decode';
import config from './config';

function App() {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const savedToken = sessionStorage.getItem('token');
        if (savedToken) {
            setToken(savedToken);
        }
    }, []);

    const refreshTokenRequest = useCallback(async () => {
        try {
            const response = await axios.post(`${config.API_URL}/auth/refresh-token`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                _isRefreshTokenRequest: true
            });
            if (response.status === 200) {
                const { accessToken } = response.data;
                handleSetToken(accessToken);
                return accessToken;
            } else {
                handleLogout();
                return null;
            }
        } catch (error) {
            handleLogout();
            return null;
        }
    }, []);

    const scheduleTokenRefresh = useCallback((token) => {
        try {
            const { exp } = jwtDecode(token);
            const expirationTime = (exp * 1000) - Date.now() - 60000; // Обновляем за минуту до истечения

            if (expirationTime > 0) {
                setTimeout(() => {
                    refreshTokenRequest();
                }, expirationTime);
            } else {
                handleLogout();
            }
        } catch (error) {
            handleLogout();
        }
    }, [refreshTokenRequest]);

    const handleSetToken = (newToken) => {
        setToken(newToken);
        sessionStorage.setItem('token', newToken);
    };

    const handleLogout = () => {
        setToken(null);
        sessionStorage.removeItem('token');
    };

    useEffect(() => {
        if (token) {
            scheduleTokenRefresh(token);
        }
    }, [token, scheduleTokenRefresh]);

    return (
        <div className="App">
            {!token ? (
                <Login setToken={handleSetToken} />
            ) : (
                <>
                    <Header />
                    <CreateBot token={token} />
                </>
            )}
        </div>
    );
}

export default App;
