import React from 'react';

const ModalContent = () => {
  return (
    <div className='modalContentInfo'>
      <h2>API Endpoints</h2>
        <table>
          <thead>
            <tr>
              <th>HTTP Request</th>
              <th>Request Parameter</th>
              <th>Response Parameters</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td>/api/get-phone-number</td>
                <td></td>
                <td>phoneNumber</td>
                <td>Returns the bot's phone number that is currently in use</td>
              </tr>
              <tr>
                <td>/api/get-users</td>
                <td>botId</td>
                <td>phone, ticket_number, registration_user_date, bot_id, bot_phone</td>
                <td>Returns information about bot users by botId</td>
              </tr>
          </tbody>
        </table>
        <h3>Request Code Example</h3>
        <div className='modalContentAttention'>Make requests only through php or node.js</div>
          <a href="/proxy.php" download="proxy.php">
                  <button>Download Example proxy.php</button>
          </a>
        <pre>
          {`
          <script>
          async function updateWhatsAppLink() {
            try {
              const response = await fetch('proxy.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
                });
                const data = await response.json();
                const phoneNumber = data.phoneNumber;
              }catch (error) {
                console.error(error);
              }
            }
            window.onload = updateWhatsAppLink;
          </script>
        `}
        </pre>
    </div>
  );
};

export default ModalContent;
