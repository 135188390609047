import React, { useState, useEffect } from 'react';
import { connectWebSocket } from '../socket';
import Sender from './Sender';
import BotsList from './BotsList';
import axios from 'axios';
import config from '../config';

const CreateBot = ({ token }) => {
    const [bots, setBots] = useState([]);
    const [authStatus, setAuthStatus] = useState('');
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [proxy, setProxy] = useState('');
    const [proxyWarning, setProxyWarning] = useState('');
    const [proxyStatus, setProxyStatus] = useState(false);
    const [selectedBot, setSelectedBot] = useState(null);

    useEffect(() => {
    
        const socket = connectWebSocket(token, (event) => {
            try {
                const message = JSON.parse(event.data);
    
                if (message.status === 'qr') {
                    setQrCodeUrl(message.qrCodeDataUrl);
                } else if (message.status === 'ready') {
                    setAuthStatus(message.status);
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        });
    
        return () => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, [token]);
    
    

    const createBot = async () => {
        if (!proxy) {
            setProxyWarning('Please add proxy.');
            return;
        } else {
            setProxyWarning('');
            try {
                const response = await axios.post(`${config.API_URL}/dashboard/create-bot`, { proxy }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
    
                const data = response.data;
                setBots((prevBots) => [...prevBots, { botId: data.botId }]);
                setAuthStatus('');
            } catch (error) {
                console.error('Error creating bot:', error);
            }
        }
    };
    

    const handleProxyChange = (e) => {
        setProxy(e.target.value);
        setProxyWarning('')
        setProxyStatus(false);
    };
    
    const checkProxy = async () => {
        if (!proxy) {
            setProxyWarning('Please enter a proxy URL.');
            return;
        }

        // Проверка формата прокси
        if (!proxy.startsWith('http://') && !proxy.startsWith('https://')) {
            setProxyWarning('Invalid proxy format. Use http://login:pass@ip:port.');
            return;
        }

        try {
            const response = await axios.post(`${config.API_URL}/dashboard/check-proxy`, { proxy }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.success) {
                setProxyWarning(`Proxy is working. IP: ${response.data.data.query}`);
                setProxyStatus(true);
            } else {
                setProxyWarning(`Proxy ${proxy} is not working. Message: ${response.data.message}`);
                setProxyStatus(false);
            }
        } catch (error) {
            setProxyWarning(`Proxy ${proxy} failed: ${error.message}`);
            setProxyStatus(false);
        }
    };

    return (
        <>
            <div className="botInfo">
                <div className='botList'>
                    <BotsList setSelectedBot={setSelectedBot} selectedBot={selectedBot} token={token} />
                </div>
                <div className='createBot'>
                    <div className='createBotBlock'>
                        <div className='proxyBlock'>
                            <input type="text" placeholder="Proxy: http://login:password@ip:port" value={proxy} onChange={handleProxyChange}/>
                            <button onClick={checkProxy} className="proxyBtn">Check Proxy</button>
                        </div>
                        {proxyWarning && <div className={`warning ${proxyStatus ? 'success' : ''}`}>{proxyWarning}</div>}
                        <button onClick={createBot} className="botBtn">Create New Bot</button>
                    </div>
                    <div>
                        {authStatus === 'ready' ? (
                            <p>Client is ready!</p>
                        ) : (
                            <>
                                {bots.map((bot, index) => (
                                    <p key={index}>Creating qrcode for {bot.botId}. Wait!</p>
                                ))}
                                {qrCodeUrl && (
                                    <div>
                                        <h2>Scan QR Code to Login</h2>
                                        <img src={qrCodeUrl} alt="QR Code" />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Sender selectedBot={selectedBot} token={token} />
        </>
    );
};

export default CreateBot;
