import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';

const Sender = ({ selectedBot, token }) => {
    const [message, setMessage] = useState('');
    const [image, setImage] = useState(null);
    const [secret, setSecret] = useState('');
    const [broadcastResponse, setBroadcastResponse] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    const handleBroadcastSubmit = async (e) => {
        e.preventDefault();
    
        if (!selectedBot) {
            setWarningMessage('You have not selected a bot to send messages');
            return;
        }
    
        const formData = new FormData();
        formData.append('botId', selectedBot);
        formData.append('message', message);
        formData.append('secret', secret);
        if (image) {
            formData.append('image', image);
        }
    
        try {
            const response = await axios.post(`${config.API_URL}/dashboard/sender`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            setBroadcastResponse(response.data);
            setWarningMessage('');
        } catch (error) {
            console.error('Error sending broadcast:', error);
            setBroadcastResponse('Ошибка при отправке сообщения');
        }
    };
    

    return (
        <div className='senderBlock'>
            <h3>Send Messages</h3>
            <form onSubmit={handleBroadcastSubmit} encType="multipart/form-data">
                <div className='senderFormBlock'>
                    <div className='senderFormBlockItem'>
                        <textarea
                            name="message"
                            id="message"
                            rows="4"
                            cols="50"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Message ..."
                            required
                        ></textarea>
                    </div>
                    <div className='senderFormBlockItem second'>
                        <input
                            type="password"
                            name="secret"
                            id="secret"
                            value={secret}
                            onChange={(e) => setSecret(e.target.value)}
                            placeholder="Secret Key"
                            required
                        />
                        <input
                            type="file"
                            name="image"
                            id="image"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>
                </div>
                <button type="submit">Send Messages</button>
                {warningMessage && <div className='warning'>{warningMessage}</div>}
            </form>
            {broadcastResponse && <p>{broadcastResponse}</p>}
        </div>
    )
}

export default Sender;
