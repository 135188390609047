import React, { useState, useEffect } from 'react';
import { connectWebSocket } from '../socket';
import { formatBotInfo } from '../utils/formatUtils';
import axios from 'axios';
import config from '../config';
import ConfirmationModal from './Modal/ConfirmationModal';


const BotsList = ({ setSelectedBot, selectedBot, token }) => {
    const [botsInfo, setBotsInfo] = useState([]);
    const [usersInfo, setUsersInfo] = useState([]);
    const [usersByBot, setUsersByBot] = useState(null);
    const [arrowDirection, setArrowDirection] = useState('down');
    const [warning, setWarning] = useState('');
    const [deleteSessionStatus, setDeleteSessionStatus] = useState(false);
    const [foldersToDelete, setFoldersToDelete] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [botToDelete, setBotToDelete] = useState(null);
    const [deleteBotStatus, setDeleteBotStatus] = useState(false)
    const [showDeleteUsersConfirmation, setShowDeleteUsersConfirmation] = useState(false);
    const [botToDeleteUsers, setBotToDeleteUsers] = useState(null);
    const [deleteUsersByBotStatus, setDeleteUsersByBotStatus] = useState(false)


    useEffect(() => {
        const socket = connectWebSocket(token, (event) => {
            const message = JSON.parse(event.data);

            if (message.status === 'botsList') {
                setBotsInfo(message.allBotsList.map(formatBotInfo));
            }else if(message.status === 'usersList') {
                setUsersInfo(message.allUsersList)
            }
        });

        const botsAllInfo = async () => {
            try {
                const response = await axios.post(`${config.API_URL}/dashboard/info`, {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
    
                const data = response.data;
    
                setBotsInfo(data.allBotsList.map(formatBotInfo));
                setUsersInfo(data.allUsersList);
            } catch (error) {
                console.error('Error getting bots info:', error);
            }
        };
        
        botsAllInfo();

        return () => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, [token]);

    const toggleUserList = (botId) => {
        setUsersByBot(usersByBot === botId ? null : botId);
        setArrowDirection(arrowDirection === 'up' ? 'down' : 'up');
    };

    const toggleBotForSender = (botId) => {
        setSelectedBot(selectedBot === botId ? null : botId);
    };

    const extractIP = (proxyUrl) => {
        try {
            const url = new URL(proxyUrl);
            const host = url.hostname;
            return host.split(':')[0];
        } catch (error) {
            console.error('Error when retrieving IP from proxy URL:', error);
            return 'Invalid URL';
        }
    };

    const fetchSessionsToDelete = async () => {
        try {
            const response = await axios.get(`${config.API_URL}/dashboard/sessions-to-delete`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            const folders = response.data;
    
            if (folders.length === 0) {
                setWarning('No folders to delete');
                setShowConfirmation(false);
            } else {
                setFoldersToDelete(folders);
                setShowConfirmation(true);
                setWarning('');
            }
        } catch (error) {
            console.error('Error fetching sessions to delete:', error);
        }
    };    

    const deleteSessions = async () => {
        try {
            const response = await axios.post(`${config.API_URL}/dashboard/clean-sessions`, { foldersToDelete }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setWarning(response.data);
                setDeleteSessionStatus(true);
                setShowConfirmation(false);
            }
        } catch (error) {
            setWarning(error.response.data);
            setDeleteSessionStatus(false);
            console.error('Error deleting sessions:', error);
        }
    };

    const checkDeleteConfirmation = () =>{
        if(selectedBot){
            setBotToDelete(selectedBot)
            setShowDeleteConfirmation(true);
        } else {
            setWarning('You have not selected a bot to delete!')
        }
    }

    const deleteBot = async () => {
        try {
            const response = await axios.post(`${config.API_URL}/dashboard/delete-bot`, { botId: botToDelete }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setBotsInfo(botsInfo.filter(bot => bot.bot_id !== botToDelete));
                setWarning('Bot deleted successfully');
                setDeleteBotStatus(true);
                setShowDeleteConfirmation(false);
            }
        } catch (error) {
            console.error('Error deleting bot:', error);
            setWarning('Failed to delete bot');
            setDeleteBotStatus(false)
            setShowDeleteConfirmation(false);
        }
    };

    const checkDeleteUsersConfirmation = () => {
        if (selectedBot) {
            setBotToDeleteUsers(selectedBot);
            setShowDeleteUsersConfirmation(true);
        } else {
            setWarning('You have not selected a bot to delete its users!');
        }
    };

    const deleteAllUsersOfBot = async () => {
        try {
            const response = await axios.post(`${config.API_URL}/dashboard/delete-all-users`, { botId: botToDeleteUsers }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (response.status === 200) {
                setUsersInfo(usersInfo.filter(user => user.bot_id !== botToDeleteUsers));
                setWarning('All users of the bot deleted successfully');
                setDeleteUsersByBotStatus(true)
                setShowDeleteUsersConfirmation(false);
            }
        } catch (error) {
            console.error('Error deleting all users of the bot:', error);
            setWarning('Failed to delete users');
            setDeleteUsersByBotStatus(false)
            setShowDeleteUsersConfirmation(false);
        }
    };
    

    return (
        <div>
        <table>
            <thead>
                <tr>
                    <th colSpan="5">
                        <button className='btn' onClick={fetchSessionsToDelete}>Delete Unauthorized Sessions</button>
                        <button className='btn' onClick={checkDeleteConfirmation}>Delete Bot</button>
                        <button className='btn' onClick={checkDeleteUsersConfirmation}>Delete Users</button>
                    </th>
                    <th colSpan="3">
                        {warning && <div className={`warning ${deleteSessionStatus || deleteBotStatus || deleteUsersByBotStatus ? 'success' : ''}`}>{warning}</div>}
                    </th>
                </tr>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Bot ID</th>
                    <th>Phone Number</th>
                    <th>Bot Registration Date</th>
                    <th>Status</th>
                    <th>Proxy IP</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {botsInfo && botsInfo.map((bot, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedBot === bot.bot_id}
                                    onChange={() => toggleBotForSender(bot.bot_id)}
                                />
                            </td>
                            <td>{index + 1}</td>
                            <td>{bot.bot_id}</td>
                            <td>{bot.bot_phone}</td>
                            <td>{bot.registration_date}</td>
                            <td>{bot.status}</td>
                            <td>{extractIP(bot.static_proxy)}</td>
                            <td 
                                onClick={() => toggleUserList(bot.bot_id)}
                                className={`arrow ${usersByBot === bot.bot_id ? arrowDirection : 'down'}`}>
                            </td>
                        </tr>
                        {usersByBot === bot.bot_id && (
                            <tr>
                                <td colSpan="8">
                                    <table className='usersTable'>
                                        <thead>
                                            <tr>
                                                <th>User ID</th>
                                                <th>Phone Number</th>
                                                <th>User Registration Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersInfo && usersInfo
                                                .filter(user => user.bot_id === bot.bot_id)
                                                .map((user, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.phone}</td>
                                                    <td>{user.registration_user_date}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
        <ConfirmationModal
                title="Confirmation of sessions deletion"
                message="Are you sure you want to delete the following session folders?"
                isOpen={showConfirmation}
                onConfirm={deleteSessions}
                onCancel={() => setShowConfirmation(false)}
            >
                <ul>
                    {foldersToDelete.map((folder, index) => (
                        <li key={index}>{folder}</li>
                    ))}
                </ul>
            </ConfirmationModal>
            <ConfirmationModal
                title="Confirmation of bot deletion"
                message={`Are you sure you want to delete this bot: ${botToDelete}?`}
                isOpen={showDeleteConfirmation}
                onConfirm={deleteBot}
                onCancel={() => setShowDeleteConfirmation(false)}
            />
            <ConfirmationModal
                title="Confirmation of deleting all users"
                message={`Are you sure you want to delete all users of this bot: ${botToDeleteUsers}?`}
                isOpen={showDeleteUsersConfirmation}
                onConfirm={deleteAllUsersOfBot}
                onCancel={() => setShowDeleteUsersConfirmation(false)}
            />

        </div>
    );
};

export default BotsList;
